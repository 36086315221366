import React, { useState } from "react";
import { styled } from "../../stitches/stitches.config";
import { CookiesProvider, useCookies } from "react-cookie";
import { detailSmall, heading150 } from "../../stitches/combinedStyles";
import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { Link } from "gatsby";

const title = `We use cookies`;
const description = (
  <>
    Click ‘Accept’ to allow Plastometrex to use cookies to personalise this
    site, and to deliver ads and measure their effectiveness on other apps and
    websites, inclusing social media. Click ‘Reject’ if you do not want us to
    use cookies for this purpose. Find out more in our{" "}
    <Link to="/privacy-policy">Privacy Policy</Link>
  </>
);
const CookieConsent = () => {
  return (
    <CookiesProvider>
      <ConsentContent />
    </CookiesProvider>
  );
};
const ConsentContent = () => {
  const [preferenceSet, setPreferenceSet] = useState<boolean>(false);
  const location = useLocation();
  const [cookies, setCookie] = useCookies([
    "gatsby-gdpr-google-tagmanager",
    "gatsby-gdpr-hotjar",
  ]);
  if (
    (Object.keys(cookies).includes("gatsby-gdpr-google-tagmanager") &&
      Object.keys(cookies).includes("gatsby-gdpr-hotjar")) ||
    preferenceSet
  ) {
    return null;
  }

  const setBothCookies = (value: boolean) => {
    setPreferenceSet(true);
    const expiryDate = new Date(
      new Date().setFullYear(new Date().getFullYear() + 1)
    );
    setCookie("gatsby-gdpr-google-tagmanager", value, {
      path: "/",
      expires: expiryDate,
    });
    setCookie("gatsby-gdpr-hotjar", value, { path: "/", expires: expiryDate });
    if (value) {
      initializeAndTrack(location);
    }
  };

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <ButtonsContainer>
        <Reject onClick={() => setBothCookies(false)}>Reject</Reject>
        <Accept onClick={() => setBothCookies(true)}>Accept</Accept>
      </ButtonsContainer>
    </Container>
  );
};

export default CookieConsent;

const Container = styled("div", {
  position: "fixed",
  bottom: 10,
  right: 10,
  width: "95%",
  maxWidth: 552,
  backgroundColor: "$white",
  borderRadius: 8,
  padding: 16,
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
  zIndex: 5,
});
const ButtonsContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
});
const Title = styled("h5", {
  ...heading150,
  margin: 0,
  marginBottom: 16,
});
const Description = styled("p", {
  ...detailSmall,
  margin: 0,
  marginBottom: 16,
});
const Reject = styled("button", {
  padding: "8px 16px",
  border: 0,
  backgroundColor: "$transparent",
  fontFamily: "$inter",
  fontWeight: 600,
  fontSize: 14,
  lineHeight: "26px",
  color: "$black100",
  borderRadius: 6,
  cursor: "pointer",
});
const Accept = styled(Reject, {
  color: "$white",
  backgroundColor: "$black100",
  marginLeft: 10,
});
