import React from "react";

import LinkedInIcon from "./assets/linkedin.inline.svg";
import TwitterIcon from "./assets/twitter.inline.svg";
import YoutubeIcon from "./assets/youtube.inline.svg";
import { styled } from "../../../stitches/stitches.config";

const socials = [
  {
    linkPath: "https://www.linkedin.com/company/53412217/",
    icon: <LinkedInIcon />,
  },
  {
    linkPath: "https://twitter.com/plastometrex/",
    icon: <TwitterIcon />,
  },
  {
    linkPath: "https://www.youtube.com/channel/UCjqyxzfxPrfm7vRr-MbmkCg",
    icon: <YoutubeIcon />,
  },
];

const SocialsBar = () => {
  return (
    <Container>
      {socials.map((social) => (
        <IconWrapper
          key={social.linkPath}
          href={social.linkPath}
          target="_blank"
          rel="noopener noreferrer"
        >
          {social.icon}
        </IconWrapper>
      ))}
    </Container>
  );
};

export default SocialsBar;

const Container = styled("div", {
  width: 120,
  display: "flex",
  justifyContent: "space-between",
});
const IconWrapper = styled("a", {
  "& svg": {
    "& *": {
      fill: "$blue100",
    },
    "& .background-circle": {
      fill: "$white",
      opacity: 0.5,
      transition: "opacity 0.3s",
    },
  },
  "&:hover": {
    "& svg": {
      "& .background-circle": {
        opacity: 1,
      },
    },
  },
});
