import { createCss } from "@stitches/react";

export const {
  styled,
  css,
  global,
  keyframes,
  getCssString,
  theme,
} = createCss({
  theme: {
    colors: {
      white: "white",
      offwhite: "#F9F9FB",
      plxBlue: "#0077FF",
      black100: "#1A1A1A",
      black80: "rgba(26, 26, 26, 0.80)",
      black55: "rgba(26, 26, 26, 0.55)",
      black10: "rgba(26, 26, 26, 0.10)",
      bayoux: "#5A7184",
      blue150: "#004CBA",
      blue100: "#1565D8",
      blue50: "rgba(21, 101, 216, 0.5)",
      blue10: "rgba(21, 101, 216, 0.1)",
      transparent: "rgba(0,0,0,0)",
    },
    fonts: {
      inter: "Inter",
      opensans: "Open Sans",
    },
  },
  media: {
    xs: "(min-width: 400px)",
    sm: "(min-width: 500px)",
    md: "(min-width: 800px)",
    lg: "(min-width: 1000px)",
    l: "(min-width: 1200px)",
    xl: "(min-width: 1440px)",
  },
  utils: {},
  prefix: "",
  insertionMethod: "append",
});
