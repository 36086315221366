/*
 * This file contains the various styling for text content to be reused
 * throughout the application.
 */

// Headings
const baseHeading = {
  fontWeight: "600",
  fontFamily: "$inter",
  color: "$black100",
};

export const heading500 = {
  ...baseHeading,
  fontSize: 63,
  lineHeight: "74px",
  letterSpacing: -2,
};

export const heading400 = {
  ...baseHeading,
  fontSize: 48,
  lineHeight: "51px",
};

export const heading300 = {
  ...baseHeading,
  fontWeight: "600",
  fontSize: 42,
  lineHeight: "52px",
};

export const heading200 = {
  ...baseHeading,
  fontWeight: "600",
  fontSize: 32,
  lineHeight: "38px",
};

export const heading150 = {
  ...baseHeading,
  fontWeight: "600",
  fontSize: 24,
  lineHeight: "34px",
};

export const heading100 = {
  ...baseHeading,
  fontWeight: "600",
  fontSize: 20,
  lineHeight: "26px",
  letterSpacing: -0.4,
};
export const heading100Light = {
  ...heading100,
  fontWeight: "500",
};

export const heading50 = {
  ...baseHeading,
  fontWeight: "600",
  fontSize: 16,
  lineHeight: "26px",
};

// Main body content

const baseText = {
  fontFamily: "$opensans",
  fontWeight: "400",
  letterSpacing: -0.36,
  color: "$bayoux",
};

export const paragraphTextLarge = {
  ...baseText,
  fontSize: 20,
  lineHeight: "30px",
};

export const paragraphTextMedium = {
  ...baseText,
  fontSize: 18,
  lineHeight: "32px",
};

export const paragraphTextSmall = {
  ...baseText,
  fontSize: 17,
  lineHeight: "26px",
};

// Other
export const detailMedium = {
  ...baseHeading,
  fontWeight: "400",
  fontSize: 16,
  lineHeight: "26px",
  letterSpacing: -0.29,
  color: "$bayoux",
};

export const detailLarge = {
  ...baseHeading,
  fontWeight: "500",
  fontSize: 20,
  lineHeight: "30px",
  letterSpacing: -0.36,
  color: "$bayoux",
};

export const detailSmall = {
  ...detailMedium,
  fontSize: 14,
  lineHeight: "22px",
  letterSpacing: -0.25,
  color: "$bayoux",
};

export const date = {
  ...baseHeading,
  color: "$blue100",
  fontSize: 12,
  lineHeight: "16px",
  letterSpacing: -0.22,
  fontWeight: 500,
};
