import React, { useState } from "react";
import { styled } from "../../../stitches/stitches.config";

import Button from "../Button/Button";
import { headerLinks } from "../../../constants/headerLinks";

import Logo from "../../../images/logo.inline.svg";
import MenuIcon from "./assets/menu.inline.svg";
import CloseIcon from "./assets/close.inline.svg";
import PlusIcon from "./assets/plus.inline.svg";
import MinusIcon from "./assets/minus.inline.svg";

import { detailSmall, heading100 } from "../../../stitches/combinedStyles";
import SocialsBar from "../SocialsBar/SocialsBar";
import { Link } from "gatsby";

interface HeaderButtonProps {
  linkText: string;
  linkPath: string;
}

export const HeaderLinkOryg = ({ linkText, linkPath }: HeaderButtonProps) => {
  if (linkPath.charAt(0) === "/") {
    return <InternalLink to={linkPath}>{linkText}</InternalLink>;
  }
  return (
    <ExternalLink href={linkPath} target="_blank" rel="noopener noreferrer">
      {linkText}
    </ExternalLink>
  );
};

export const HeaderLink = ({ linkText, linkPath, subLinks, className }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {subLinks && (
          <div onClick={() => setSubMenuOpen(!subMenuOpen)}>
            {!subMenuOpen ? <PlusStyled /> : <MinusStyled />}
          </div>
        )}

        {linkPath === null ? (
          <NullLink onClick={() => setSubMenuOpen(!subMenuOpen)}>
            {linkText}
          </NullLink>

        ) : linkPath.charAt(0) === "/" ? (
          <InternalLink to={linkPath} className={className}>
            {linkText}
          </InternalLink>
        ) : (
          <ExternalLink href={linkPath} target="_blank" rel="noopener noreferrer" className={className}>
            {linkText}
          </ExternalLink>
        )}
      </div>

      {subLinks && subMenuOpen && (
        <SubLinksContainer>
          {subLinks.map((subLink,i) => (
            <HeaderLink className='mobile-sub-link' key={subLink.linkPath+subLink.linkText+i} {...subLink} />
          ))}
        </SubLinksContainer>
      )}
    </div>
  );
};

const MobileMenu = () => {
  const [menuActive, setMenuActive] = useState<boolean>(false);
  return (
    <Container>
      <TopBar>
        <MenuIcon onClick={() => setMenuActive(true)} />
        <LogoWrapper to="/">
          <Logo />
        </LogoWrapper>
        <ButtonWrapper>
          <Link to="/book-a-demo">
            <Button tier="primary" size="xSmall">
              Book Demo
            </Button>
          </Link>
        </ButtonWrapper>
      </TopBar>
      <ContentContainer active={menuActive}>
        <CloseWrapper onClick={() => setMenuActive(false)}>
          <CloseIcon />
        </CloseWrapper>
        <MenuWrapper>
          <LinksContainer>
            {headerLinks.map((link,i) => (
              <HeaderLink
                key={link.linkPath+link.linkText+i}
                linkText={link.linkText}
                linkPath={link.linkPath}
                subLinks={link.subLinks}
              />
            ))}
          </LinksContainer>
          <SocialsContainer>
            <SocialsBar />
          </SocialsContainer>
          <SubLink to="warranty-registration">Warranty Registration</SubLink>
        </MenuWrapper>
      </ContentContainer>
    </Container>
  );
};

export default MobileMenu;

const Container = styled("div", {
  position: "relative",
  backgroundColor: "$white",
});

const TopBar = styled("div", {
  display: "flex",
  padding: "9px 9px 9px 15px",
  alignItems: "center",
  boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.05)",
});

const ContentContainer = styled("div", {
  backgroundColor: "$blue100",
  position: "fixed",
  height: "100vh",
  overflowY: "auto",
  width: "100%",
  display: "none",
  top: 0,
  left: 0,
  right: 0,
  zIndex: "10",
  variants: {
    active: {
      true: {
        display: "flex",
      },
    },
  },
});

const LogoWrapper = styled(Link, {
  margin: 0,
  height: 24,
  marginLeft: 18,
  "& .px": {
    fill: "$blue100",
  },
  "& svg": {
    height: "100%",
    widht: "auto",
  },
});
const ButtonWrapper = styled("div", {
  marginLeft: "auto",
});

const MenuWrapper = styled("div", {
  width: 209,
  margin: "60px auto 40px auto",
});

const LinksContainer = styled("div", {
  textAlign: "left",
  borderBottom: "1px solid rgba(255,255,255,0.1)",
  display: "flex",
  flexDirection: "column",
});

const InternalLink = styled(Link, {
  ...heading100,
  color: "$white",
  margin: 0,
  '&.mobile-sub-link': {
    fontWeight: 400,
    fontSize: 16
  }
});

const NullLink = styled('div', {
  ...heading100,
  color: "$white",
  margin: 0,
  '&.mobile-sub-link': {
    fontWeight: 400,
    fontSize: 16
  }
});


const ExternalLink = styled("a", {
  ...heading100,
  color: "$white",
  margin: 0,
  '&.mobile-sub-link': {
    fontWeight: 400,
    fontSize: 16
  }
});

const SocialsContainer = styled("div", {
  paddingTop: 20,
  width: 80,
  margin: "auto",
});

const SubLink = styled(Link, {
  ...detailSmall,
  color: "$white",
  opacity: "0.7",
  textAlign: "left",
  margin: "16px auto",
  display: "block",
});

const CloseWrapper = styled("div", {
  position: "absolute",
  top: 20,
  left: 26,
});
const SubLinksContainer = styled("div", {
  backgroundColor: "$blue100",
  marginTop: 8,
  width: '300px',
  paddingLeft: '50px',
  marginBottom: 14,
});

const PlusStyled = styled(PlusIcon, {
  marginRight: "8px",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  borderRadius: "50%",
  padding: "4px",
});

const MinusStyled = styled(MinusIcon, {
  marginRight: "8px",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  borderRadius: "50%",
  padding: "4px",
});