import React, { ReactNode } from "react";
import { styled } from "../../../stitches/stitches.config";

import ArrowRightIcon from "./arrow_right.inline.svg";

export interface ButtonProps {
  tier?: "primary" | "secondary" | "bordered" | "disabled";
  size?: "large" | "medium" | "small" | "xSmall";
  noPadding?: boolean;
  download?: boolean;
  arrow?: boolean;
  inverted?: boolean;
  fullWidth?: boolean;
  children: ReactNode;
}

const Button = <T extends Record<string, unknown>>({
  tier = "primary",
  size = "medium",
  arrow = false,
  inverted = false,
  fullWidth = false,
  noPadding = false,
  download = false,
  children,
  ...rest
}: ButtonProps & T) => {
  return (
    <Btn
      tier={tier}
      size={size}
      inverted={inverted}
      noPadding={noPadding}
      fullWidth={fullWidth}
      {...rest}
    >
      {children}
      {arrow && (
        <IconContainer>
          <ArrowRight />
        </IconContainer>
      )}
    </Btn>
  );
};

export default Button;

const Btn = styled("button", {
  borderRadius: 8,
  outline: "none",
  fontFamily: "$inter",
  fontWeight: "600",
  textAlign: "center",
  border: 0,
  display: "flex",
  justifyContent: "center",
  cursor: "pointer",
  variants: {
    size: {
      large: {
        fontSize: 18,
        fontFamily: "$opensans",
        fontWeight: "700",
        lineHeight: "24.5px",
        padding: "15px 24px",
      },
      medium: {
        fontSize: 16,
        lineHeight: "26px",
        padding: "11px 20px",
      },
      small: {
        fontSize: 16,
        lineHeight: "26px",
        padding: "8px 16px",
      },
      xSmall: {
        fontSize: 14,
        lineHeight: "26px",
        padding: "6px 16px",
      },
    },
    tier: {
      primary: {
        backgroundColor: "$blue100",
        color: "$white",
        "&:hover": {
          backgroundColor: "$blue150",
        },
        "& svg": {
          path: {
            fill: "$white",
          },
        },
      },
      secondary: {
        background: "$transparent",
        color: "$blue100",
        "& svg": {
          path: {
            fill: "$blue100",
          },
        },
        "&:hover": {
          color: "$blue150",
          "& svg": {
            path: {
              fill: "$blue150",
            },
          },
        },
      },
      bordered: {
        backgroundColor: "$transparent",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "$blue100",
        color: "$blue100",
        "& svg": {
          path: {
            fill: "$blue100",
          },
        },
        "&:hover": {
          color: "$blue150",
          borderColor: "$blue150",
          "& svg": {
            path: {
              fill: "$blue150",
            },
          },
        },
      },
      disabled: {
        backgroundColor: "$black10",
        color: "$bayoux",
      },
    },
    inverted: {
      true: {},
      false: {},
    },
    fullWidth: {
      true: {
        width: "100%",
      },
    },
    noPadding: {
      true: {
        padding: 0,
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      inverted: true,
      tier: "primary",
      css: {
        color: "$blue100",
        backgroundColor: "$white",
        "& svg": {
          path: {
            fill: "$blue100",
          },
        },
        "&:hover": {
          color: "$blue150",
          backgroundColor: "$white",
          "& svg": {
            path: {
              fill: "$blue150",
            },
          },
        },
      },
    },
    {
      inverted: true,
      tier: "secondary",
      css: {
        color: "$white",
        "& svg": {
          path: {
            fill: "$white",
          },
        },
        "&:hover": {
          color: "$offwhite",
          "& svg": {
            path: {
              fill: "$offwhite",
            },
          },
        },
      },
    },
    {
      inverted: true,
      tier: "bordered",
      css: {
        color: "$white",
        borderColor: "$white",
        "& svg": {
          path: {
            fill: "$white",
          },
        },
        "&:hover": {
          color: "$offwhite",
          borderColor: "$offwhite",
          "& svg": {
            path: {
              fill: "$offwhite",
            },
          },
        },
      },
    },
  ],
});

const IconContainer = styled("div", { paddingLeft: 10 });

const ArrowRight = styled(ArrowRightIcon, {});
