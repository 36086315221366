import React from "react";
import { Link } from "gatsby";

import { styled } from "../../../stitches/stitches.config";
import { headerLinks } from "../../../constants/headerLinks";

import LogoSVG from "../../../images/logoWithText.inline.svg";
import Button from "../Button/Button";
import MobileMenu from "./MobileMenu";

interface HeaderButtonProps extends HeaderProps {
  linkText: string;
  linkPath: string;
}

export const HeaderLink = ({
  backgroundColorUnderneath,
  linkText,
  linkPath,
  items,
}: HeaderButtonProps & { items?: SubLinkType[] }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const renderSubMenu = (items: SubLinkType[]) => {
    return (
      <SubMenuWrapper>
        {items.map((item, i) => (
          <SubMenuLink
            to={item.linkPath}
            key={item.linkPath + item.linkText + i}
          >
            {item.linkText}
          </SubMenuLink>
        ))}
      </SubMenuWrapper>
    );
  };

  return (
    <HeaderLinkWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {linkPath === null ? (
        <NullLink>
          <LinkButton colorUnderneath={backgroundColorUnderneath}>
            {linkText}
          </LinkButton>
        </NullLink>
      ) : (
        <InternalLink to={linkPath}>
          <LinkButton colorUnderneath={backgroundColorUnderneath}>
            {linkText}
          </LinkButton>
        </InternalLink>)}
      {items && isHovered && renderSubMenu(items)}
    </HeaderLinkWrapper>
  );
};

export interface HeaderProps {
  backgroundColorUnderneath: "dark" | "blue" | "white";
}

const Header = ({ backgroundColorUnderneath }: HeaderProps) => {
  return (
    <Wrapper backgroundColorUnderneath={backgroundColorUnderneath}>
      <Container>
        <ContentWrapper>
          <LogoWrapper to="/" colorUnderneath={backgroundColorUnderneath}>
            <LogoSVG />
          </LogoWrapper>
          <LinksContainer>
            {headerLinks.map((headerLink, i) => (
              <HeaderLink
                key={headerLink.linkText + headerLink.linkPath + i}
                backgroundColorUnderneath={backgroundColorUnderneath}
                linkPath={headerLink.linkPath}
                linkText={headerLink.linkText}
                items={headerLink.subLinks}
              />
            ))}
          </LinksContainer>
          <Link to="/book-a-demo">
            <Button
              size="small"
              tier="primary"
              arrow={true}
              inverted={backgroundColorUnderneath === "blue"}
            >
              Book Demo
            </Button>
          </Link>
        </ContentWrapper>
        <MobileMenuWrapper>
          <MobileMenu />
        </MobileMenuWrapper>
      </Container>
    </Wrapper>
  );
};

export default Header;

const MobileMenuWrapper = styled("div", {
  display: "block",
  "@lg": {
    display: "none",
  },
});

const LinkButton = styled("button", {
  cursor: "pointer",
  border: 0,
  outline: "none",
  borderRadius: 4,
  backgroundColor: "$transparent",
  padding: "4px 14px",
  margin: "0 1px",
  fontFamily: "$inter",
  fontWeight: "600",
  fontSize: 16,
  variants: {
    colorUnderneath: {
      white: {
        color: "$black100",
        "&:hover": {
          backgroundColor: "$blue10",
        },
      },
      dark: {
        color: "$white",
        "&:hover": {
          backgroundColor: "$blue50",
        },
      },
      blue: {
        color: "$white",
        "&:hover": {
          backgroundColor: "rgba(230, 240, 255, 0.5)",
        },
      },
    },
  },
});

const Wrapper = styled("div", {
  position: "relative",
  zIndex: 10,
  variants: {
    backgroundColorUnderneath: {
      dark: {
        background:
          "linear-gradient(180deg, rgba(9, 9, 9, 0.8) 0%, rgba(9, 9, 9, 0) 100%)",
      },
      white: {},
      blue: {},
    },
  },
});

const Container = styled("div", {
  width: "100%",
  margin: "0 auto",
  maxWidth: 1440,
  zIndex: 10,
  position: "relative",
  backgroundColor: "$white",
  "@md": {
    backgroundColor: "$transparent",
  },
});

const ContentWrapper = styled("div", {
  height: 80,
  padding: "0 35px",
  alignItems: "center",
  display: "none",
  "@lg": {
    display: "flex",
  },
});

const LinksContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  marginLeft: "auto",
  marginRight: 26,
});

const LogoWrapper = styled(Link, {
  margin: 0,
  variants: {
    colorUnderneath: {
      white: {
        "& .px": {
          fill: "$black100",
        },
      },
      dark: {
        "& .px": {
          fill: "$white",
        },
      },
      blue: {
        path: {
          fill: "$white",
        },
      },
    },
  },
});

const InternalLink = styled(Link, {
  "&:nth-last-child(1), &:nth-last-child(4)": {
    display: "none",
  },
  "@l": {
    "&:nth-last-child(1), &:nth-last-child(4)": {
      display: "inline-block",
    },
  },
});

const NullLink = styled('div', {
  "&:nth-last-child(1), &:nth-last-child(4)": {
    display: "none",
  },
  "@l": {
    "&:nth-last-child(1), &:nth-last-child(4)": {
      display: "inline-block",
    },
  },
});

const ExternalLink = styled("a", {
  "&:nth-last-child(1), &:nth-last-child(4)": {
    display: "none",
  },
  "@l": {
    "&:nth-last-child(1), &:nth-last-child(4)": {
      display: "inline-block",
    },
  },
});

const SubLinksContainer = styled("div", {
  backgroundColor: "$blue100",
  position: "absolute",
  marginTop: 8,
  borderRadius: 4,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)"
});

const SubMenuWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  position: "absolute",
  top: "100%",
  left: 0,
  backgroundColor: "$white",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  width: '260px',
  padding: '6px',
  borderRadius: 4,
  border: '1px solid #f1f2f5'
});

const SubMenuLink = styled(Link, {
  padding: "8px 16px",
  textDecoration: "none",
  color: "$black",
  "&:hover": {
    backgroundColor: "$blue10",
    fontWeight: 'bold',
    borderRadius: 4,
  },
});

const HeaderLinkWrapper = styled("div", {
  position: "relative",
});
