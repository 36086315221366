import React from "react";
import { date, detailSmall, heading50 } from "../../../stitches/combinedStyles";
import { styled } from "../../../stitches/stitches.config";
import SocialsBar from "../SocialsBar/SocialsBar";
import { Link } from "gatsby";

import LogoSVG from "./logo.inline.svg";
import { footerLinks, LinkType } from "../../../constants/headerLinks";
import { knowledgeCenterLnks } from "../../knowledgeCentre/KnowledgeCentreMenu";

const FooterColumn = ({ title, links }: { title: string; links: LinkType }) => (
  <Column>
    <Title>{title}</Title>
    {links.map((link) => (
      <Sublink to={link.linkPath} key={link.linkPath}>
        {link.linkText}
      </Sublink>
    ))}
  </Column>
);

let links = [...footerLinks];
links.pop();

const Footer = () => {
  const companyLinks = [
    ...links,
    {
      linkText: "Ask a Question",
      linkPath: "/ask-a-question",
    },
    {
      linkText: "Privacy Policy",
      linkPath: "/privacy-policy",
    },
    {
      linkText: "Warranty Registration",
      linkPath: "/warranty-registration",
    },
  ];
  return (
    <Container>
      <MobileContentWrapper>
        <Title>Follow Us</Title>
        <SocialsBar />
        <Break />
        <Sublink to="/warranty-registration">Warranty Registration</Sublink>
        <Sublink to="/privacy-policy">Privacy Policy</Sublink>
        <Break />
      </MobileContentWrapper>
      <DesktopContentWrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <InnerWrapper>
          <FooterColumn title="Company" links={companyLinks} />
          <FooterColumn title="Knowledge Centre" links={knowledgeCenterLnks} />
          <Column>
            <Title>Follow Us</Title>
            <SocialsBar />
          </Column>
        </InnerWrapper>
      </DesktopContentWrapper>
      <Copyright>© {new Date().getFullYear()} Plastometrex. All rights reserved.</Copyright>
    </Container>
  );
};

export default Footer;

const Container = styled("div", {
  backgroundColor: "$blue100",
  width: "100%",
});

const MobileContentWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@md": {
    display: "none",
  },
});

const DesktopContentWrapper = styled("div", {
  display: "none",
  maxWidth: 1160,
  width: "95%",
  margin: "auto",
  position: "relative",
  borderBottom: "1px solid rgba(255,255,255,0.1)",
  marginBottom: 48,
  paddingTop: 80,
  "@md": { display: "flex" },
});

const InnerWrapper = styled("div", {
  width: 540,
  margin: "auto",
  display: "flex",
  justifyContent: "space-between",
  marginBottom: 94,
});

const Column = styled("div", {
  display: "flex",
  flexDirection: "column",
});

const Logo = styled(LogoSVG, {
  position: "absolute",
  top: 90,
  left: 0,
  height: 38,
  width: 64,
  "& path": {
    fill: "$white",
  },
});

const Break = styled("div", {
  borderBottom: "1px solid rgba(255,255,255,0.1)",
  width: 110,
  margin: "15px auto",
});

const Title = styled("p", {
  ...heading50,
  marginBottom: 8,
  color: "$white",
});

const Sublink = styled(Link, {
  ...detailSmall,
  color: "$white",
  opacity: 0.5,
  paddingTop: 8,
  paddingBottom: 7,
  textDecoration: "none",

  transition: "opacity 0.3s",
  "&:hover": {
    opacity: 0.8,
  },
});

const Copyright = styled("p", {
  ...date,
  color: "$white",
  opacity: 0.5,
  textAlign: "center",
  margin: 0,
  paddingBottom: 50,
});

const LogoWrapper = styled("div", {
  width: 64,
  "& img": {
    width: "100%",
  },
});
