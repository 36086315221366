import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

import { styled } from "../../stitches/stitches.config";
import Button from "../shared/Button/Button";

export const knowledgeCenterLnks = [
  {
    linkText: "Case Studies",
    linkPath: "/case-studies",
  },
  {
    linkText: "Blog Articles",
    linkPath: "/blog-articles",
  },
  {
    linkText: "Technical Articles",
    linkPath: "/technical-articles",
  },
  {
    linkText: "Research Papers",
    linkPath: "/research-papers",
  },
  {
    linkText: "Videos",
    linkPath: "/videos",
  },
  {
    linkText: "Webinars",
    linkPath: "/webinars",
  },
  {
    linkText: "Training & Conferences",
    linkPath: "/training-and-conferences",
  },
  {
    linkText: "FAQ",
    linkPath: "/faq",
  },
];

const KnowledgeCentreMenu = ({
  activeLinkPath,
}: {
  activeLinkPath: string;
}) => {
  return (
    <Container>
      <Title>Knowledge Centre</Title>
      <LinksContainer>
        {knowledgeCenterLnks.map((link) => (
          <div key={link.linkPath}>
            <Link to={link.linkPath}>
              <LinkButton
                className={"custom-target-link"}
                active={activeLinkPath === link.linkPath}
              >
                {link.linkText}
              </LinkButton>
            </Link>
            {(link.linkText === "Videos" ||
              link.linkText === "Training & Conferences") && <Break />}
          </div>
        ))}
        <DesktopContainer>
          <Break />
          <ButtonContainer>
            <Link to="/book-a-demo">
              <Button fullWidth={true} tier={"primary"} arrow>
                Book a demo
              </Button>
            </Link>
          </ButtonContainer>
        </DesktopContainer>
      </LinksContainer>
    </Container>
  );
};

export default KnowledgeCentreMenu;

const Container = styled("div", {
  width: "100%",
  margin: "auto",
  backgroundColor: "$white",
  padding: "22px 25px 6px 5px",
  minWidth: 240,
  "@lg": {
    width: 250,
    margin: 0,
    marginRight: 25,
    padding: "20px 5px 12px 14px",
    alignSelf: "flex-start",
    borderRadius: 8,
    border: "1px solid #eceef2",
  },
});

const Title = styled("h6", {
  fontFamily: "$inter",
  fontSize: 14,
  color: "$blue100",
  fontWeight: "600",
  textTransform: "uppercase",
  margin: 0,
  marginLeft: 14,
  marginBottom: 5,
});

const LinksContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  maxWidth: 600,
  "@sm": {
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  "@lg": {
    flexDirection: "column",
    flexWrap: "unset",
  },
  "&:hover": {
    "& .custom-target-link": {
      backgroundColor: "$transparent",
      "&:hover": {
        backgroundColor: "$blue10",
      },
    },
  },
});
const LinkButton = styled("div", {
  fontFamily: "$opensans",
  fontWeight: 400,
  fontSize: 14,
  color: "$black100",
  cursor: "pointer",
  border: 0,
  backgroundColor: "$transparent",
  outline: "none",
  padding: "8px 14px",
  borderRadius: 8,
  width: "100%",
  "&:hover": {
    backgroundColor: "$blue10",
  },
  "@sm": {
    width: 200,
    padding: "14px",
  },
  variants: {
    active: {
      true: {
        backgroundColor: "$blue10",
        fontWeight: 700,
      },
      false: {},
    },
  },
});

const Break = styled("div", {
  height: 1,
  width: 170,
  borderBottom: "1px solid #ECEEF2",
  margin: "5px 0",
  marginLeft: 15,
  "@sm": {
    display: "none",
  },
  "@lg": {
    display: "block",
  },
});

const ButtonContainer = styled("div", {
  width: "95%",
  marginTop: 33,
  paddingBottom: 10,
});

const DesktopContainer = styled("div", {
  display: "none",
  "@lg": {
    display: "block",
  },
});
