export const headerLinks = [
  {
    linkText: "Products",
    linkPath: "/products",
    subLinks: [
      {
        linkText: "Lab testing",
        linkPath: "/benchtop"
      },
      {
        linkText: "In-field testing",
        linkPath: "https://plastometrex-6034553.hs-sites.com/pip-testing-for-pipeline-inspection"
      },
      {
        linkText: "High-Temperature testing",
        linkPath: "/hotpip"
      },
    ]
  },
  {
    linkText: "Resources",
    linkPath: "/case-studies",
    subLinks: [
      {
        linkText: "Case studies",
        linkPath: "/case-studies"
      },
      {
        linkText: "Research papers",
        linkPath: "/research-papers"
      },
      {
        linkText: "Technical articles",
        linkPath: "/technical-articles"
      },
      {
        linkText: "News",
        linkPath: "/newsroom"
      },
      {
        linkText: "Blog",
        linkPath: "/blog-articles"
      },
      {
        linkText: "Videos",
        linkPath: "/videos"
      },
      {
        linkText: "Webinars",
        linkPath: "/webinars"
      },
      {
        linkText: "Training & conferences",
        linkPath: "/training-and-conferences"
      },
      {
        linkText: "FAQs",
        linkPath: "/faq"
      },
    ]
  },
  {
    linkText: "Industries",
    linkPath: null,
    subLinks: [
      {
        linkText: "Additive manufacturing",
        linkPath: "https://plastometrex-6034553.hs-sites.com/pip-testing-for-additive-manufacturing"
      },
      {
        linkText: "Energy/oil & gas",
        linkPath: "https://plastometrex-6034553.hs-sites.com/pip-testing-for-pipeline-inspection"
      },
      {
        linkText: "Advanced manufacturing",
        linkPath: "/industry"
      },
      {
        linkText: "Research & education",
        linkPath: "/academia"
      },
    ]
  },
  {
    linkText: "Tool performance",
    linkPath: null,
    subLinks: [
      {
        linkText: "Material data",
        linkPath: "/interactive-validation-data"
      },     
    ]
  },
  {
    linkText: "Company",
    linkPath:null,
    subLinks: [
      {
        linkText: "Team",
        linkPath: "/team"
      },     
    ]
  },
  {
    linkText: "Contact Us",
    linkPath: "/contact",
    subLinks: [
      {
        linkText: "Get in touch",
        linkPath: "/contact"
      },
     
    ]
  },
];

export const footerLinks = [
  {
    linkText: "Products",
    linkPath: "/products",
  },
  {
    linkText: "Knowledge Centre",
    linkPath: "/case-studies",
  },
  {
    linkText: "Industry",
    linkPath: "/industry",
  },
  {
    linkText: "Academia",
    linkPath: "/academia",
  },
  {
    linkText: "News",
    linkPath: "/newsroom",
  },
  {
    linkText: "Team",
    linkPath: "/team",
  },
  {
    linkText: "Contact Us",
    linkPath: "/contact",
  },
];

export type SubLinkType = {
  linkText: string;
  linkPath: string;
};

export type LinkType = {
  linkText: string;
  linkPath: string;
  items?: SubLinkType[];
};
